class CurrencyLanguage {
  id;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.language = data.language;
  }
}
var DataConverter = {
  toFirestore: function(item) {
    return {
      name : item.name,
      language : item.language,
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    return new CurrencyLanguage(data)
  }
}
export {CurrencyLanguage, DataConverter} 