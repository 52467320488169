<template>
  <div>
    <portal to="title-page">
      <h1>Editar {{currency ? currency.name : ''}}</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPencil}}
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small @click.stop="dialogAddLang.show = true" color="primary" class="ml-auto"><v-icon>{{mdiPlus}}</v-icon> lenguaje</v-btn>
        <v-btn small class="ml-2" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          v-if="currency && currencyLanguage"
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="currency.code"
                label="Codigo ISO 4217"
                :rules="[rules.required, rules.codeLenght, rules.alpha]"
                :prepend-icon="mdiLock"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="currency.exchangeRate"
                label="Tipo de cambio"
                :prepend-icon="mdiNumeric"
                :rules="[rules.required, rules.number]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-select
                v-if="registerLanguages != null"
                v-model="language"
                :items="registerLanguages"
                item-text="language"
                return-object
                :prepend-icon="mdiFlag"
                :rules="[v => !!v || 'El idioma es requerido']"
                label="Seleccione el idioma"
                required
                @change="changeLang"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="currencyLanguage.name"
                label="Nombre"
                :prepend-icon="mdiText"
                :rules="[rules.required]"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="!validForm"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogAddLang.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Seleccione</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormLang"
            lazy-validation
          >
            <v-select
              v-if="availableLanguages != null"
              v-model="newLang"
              :items="availableLanguages"
              item-text="name"
              return-object
              :prepend-icon="mdiFlag"
              :rules="[v => !!v || 'El idioma es requerido']"
              label="Seleccione el idioma"
              required
            ></v-select>
            <p>Una vez seleccionado el lenguaje haga click en agregar y cuando se cierre esta ventana edite los datos.</p>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="dialogAddLang.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormLang"
            @click="addLang"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import Currency from "@/models/Currency";
  import Language from "@/models/Language";
  import { mdiPlus, mdiArrowLeft, mdiFlag, mdiText, mdiClose, mdiNumeric, mdiLock, mdiPencil } from '@mdi/js';
  import {DataConverter} from "@/FirestoreDataConverters/CurrencyLanguage.js";
  export default {
    name: "CurrencyEdit",
    data() {
      return {
        mdiPlus:mdiPlus,
        mdiArrowLeft:mdiArrowLeft,
        mdiFlag:mdiFlag,
        mdiText:mdiText,
        mdiClose:mdiClose,
        mdiNumeric:mdiNumeric,
        mdiLock:mdiLock,
        mdiPencil:mdiPencil,
        //////////////////////////////////////
        currencyModel:new Currency,
        languageModel:new Language,
        //////////////////////////////////////
        unsubscribeLangs:null,
        validForm:true,
        availableLanguages:null,
        systemLanguages:null,
        registerLanguages:null,
        currency:null,
        currencyLanguage:null,
        selectedLanguageId:'es',
        language:null,
        newLang:null,
        validFormLang:true,
        //////////////////////////////////////
        dialogAddLang:{
          show:false
        },
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        rules:{
          required: v => !!v || 'El campo es obligatorio',
          codeLenght: v => v && v.length == 3 || 'El campo codigo debe contener 3 letras',
          number : v => {
            const pattern = /^[0-9]+([.][0-9]+)?$/
            return pattern.test(v) || 'Numero invalido'
          },
          alpha : v => {
            const pattern = /^[a-zA-Z]+?$/
            return pattern.test(v) || 'El codigo solo deben ser letras'
          },
        }
      }
    },
    methods:{
      validateForm() {
        if (this.$refs.form.validate()) {
          let loading = this.$loading.show();
          let data = {
            code:this.currency.code,
            exchangeRate:this.currency.exchangeRate,
          };
          if (this.currencyLanguage.id == 'es') {
            data.name = this.currencyLanguage.name;
          }
          this.ref.update(data).then(() => {
            this.ref.collection('currencyLanguages').doc(this.currencyLanguage.id).update({
              name:this.currencyLanguage.name,
              language:this.currencyLanguage.language
            })
            .then(() => {
              loading.hide();
              this.showSnackbarSuccess('La moneda se actualizo correctamente');
            })
            .catch(error => {
              loading.hide();
              console.log(error);
              this.showSnackbarError('Error al actualizar el lenguaje de la moneda');
            })
          })
          .catch( error=> {
            console.log(error);
            loading.hide();
            this.showSnackbarError('Error al actualizar la moneda');
          })
        }
      },
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      setAvailableLanguages() {
        this.availableLanguages = [];
        if (this.systemLanguages !== null) {
          if (this.registerLanguages !== null) {
            for(var i in this.systemLanguages) {
              let langId = this.systemLanguages[i].id;
              let exist = this.registerLanguages.find(item => item.id == langId);
              if (!exist) {
                this.availableLanguages.push(this.systemLanguages[i]);
              }
            }
          } else {
            this.availableLanguages = this.systemLanguages;
          }
        }
      },
      addLang() {
        if (this.$refs.formlang.validate()) {
          let loading = this.$loading.show();
          if (this.unsubscribeLangs) {
            this.unsubscribeLangs();
          }
          this.ref.collection('currencyLanguages').doc(this.newLang.id).set({
            language:this.newLang.name,
            name:''
          }).then(() => {
            this.getLangs();
            this.selectedLanguageId = this.newLang.id;
            loading.hide();
            this.showSnackbarSuccess('El lenguaje se agrego correctamente, ingrese la informacion correspendiente');
            this.dialogAddLang.show = false;
          });
        }
      },
      getLangs() {
        this.unsubscribeLangs = this.ref.collection('currencyLanguages').withConverter(DataConverter).onSnapshot(docs => {
          this.registerLanguages = []
          var index, i = 0;
          docs.forEach(doc => {
            this.registerLanguages.push(doc.data());
            if (doc.data().id == this.selectedLanguageId) {
              index = i;
            }
            i++;
          })
          this.language = this.registerLanguages[index];
          this.currencyLanguage = this.registerLanguages[index];
          this.setAvailableLanguages();
        })
      },
      changeLang() {
        this.selectedLanguageId = this.language.id;
        this.currencyLanguage = this.language;
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    mounted() {
      this.languageModel.getAll().onSnapshot(snap => {
        this.systemLanguages = [];
        snap.forEach( doc => {
          this.systemLanguages.push(doc.data());
        })
        this.setAvailableLanguages();
      })
      this.currencyModel.findById(this.$route.params.id).onSnapshot(doc => {
        if (doc.exists) {
          this.currency = {
            name:doc.data().name,
            code:doc.data().code,
            exchangeRate:doc.data().exchangeRate,
            status:doc.data().status,
          }
          this.ref = doc.ref;
          this.category = {
            id:doc.data().categoryId,
            name:doc.data().category,
          }
          if (this.unsubscribeLangs) {
            this.unsubscribeLangs();
          }
          this.getLangs();
        }
      })
    },
  }
</script>